import { Component } from "react";
import { Text } from '@chakra-ui/react'

import { VINTAGES_INFO } from "./constants/ratings";

class RatingHeader extends Component {
    render() {
        const { type, title, hideVintage = false } = this.props;

        return (
            <div className="rating-table-row">
                <div className="rating-table-cell rating-table-country">{title}</div>
                { !hideVintage ? Array.from(Array(VINTAGES_INFO.length), (_, i) => {
                    const vintage = VINTAGES_INFO.max - i;

                    return (
                        <div className="rating-table-cell rating-table-vintage" key={vintage}><Text>{vintage}</Text></div>
                    )
                }) : ""}
            </div>
        )
    }
}

export default RatingHeader;