import { Fragment } from "react";

import { Tabs, TabList, TabPanel, TabPanels, Tab } from '@chakra-ui/react'

import RatingTab from './RatingTab';

import { RATINGS, AVERAGE, CRITICS, VINTAGES_INFO, RATING_BY_REGION } from "./constants/ratings";
import COUNTRIES from "./constants/countries";

function RatingTable() {
    return (
        <>
        <Tabs isLazy>
            <TabList>
                <Tab>Average</Tab>
                {CRITICS.map((critic) => (
                    <Tab key={critic}>{ critic }</Tab>
                ))}
            </TabList>    
            <TabPanels>
                <TabPanel padding={0} marginTop={5} key="average">
                    <RatingTab key={"average"} critic={"average"} />
                </TabPanel>
                {CRITICS.map((critic) => (
                    <TabPanel padding={0} marginTop={10} key={critic}>
                        <RatingTab key={critic} critic={critic} />
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
        </>
    )
}

export default RatingTable;