import React from 'react';

import RatingTable from './RatingTable';

import { ChakraProvider } from '@chakra-ui/react'
import { Container } from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react'
import {
  extendTheme,
  withDefaultColorScheme,
  theme as baseTheme,
} from '@chakra-ui/react'

import "./App.css";


const customTheme = extendTheme(
  {
    styles: {
      global: {
        // styles for the `body`
        body: {
          bg: 'white',
          color: 'black.100',
        },
      },
    },
    colors: {
      primary: baseTheme.colors.black,
    },
    fonts: {
      body: `'Twemoji Country Flags', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
    }
  },
  withDefaultColorScheme({ colorScheme: 'primary' }),
)

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Container maxW="container.xl" marginTop={3} padding={{ base: 0, sm: 4 }}>
        <Heading padding={4}>Chart.vin</Heading>
        <RatingTable />
      </Container>
    </ChakraProvider>
  );
}

export default App;
