const countries = [
    {
        key: "france",
        name: "🇫🇷France",
        regions: [
            {
                key: "bourgogne",
                name: "Bourgogne",
                subregions: [
                {
                    key: "cote_de_nuits_rouge",
                    name: "Nuits Rouge"
                },
                {
                    key: "cote_de_beaune_rouge",
                    name: "Beaune Rouge"
                },
                {
                    key: "cote_de_beaune_blanc",
                    name: "Beaune Blanc"
                }]
            },
            {
                key: "bordeaux",
                name: "Bordeaux",
                subregions: [
                {
                    key: "pomerol",
                    name: "Pomerol"
                },
                {
                    key: "st_emilion",
                    name: "St. Emilion"
                },
                {
                    key: "pauillac",
                    name: "Pauillac"
                },
                {
                    key: "margaux",
                    name: "Margaux"
                },
                {
                    key: "graves",
                    name: "Graves"
                },
                {
                    key: "sauternes",
                    name: "Sauternes"
                }]
            },
            {
                key: "rhone",
                name: "Rhone",
                subregions: [
                {
                    key: "rhone_northern",
                    name: "Northern"
                },
                {
                    key: "rhone_southern",
                    name: "Southern"
                }]
            },
            {
                key: "others",
                name: "Others",
                subregions: [
                {
                    key: "beaujolais",
                    name: "Beaujolais"
                },
                {
                    key: "alsace",
                    name: "Alsace"
                },
                {
                    key: "champagne",
                    name: "Champagne"
                },
                {
                    key: "languedoc",
                    name: "Languedoc"
                },
                {
                    key: "roussillon",
                    name: "Roussillon"
                },
                {
                    key: "loire_rouge",
                    name: "Loire Rouge"
                },
                {
                    key: "loire_blanc",
                    name: "Loire Blanc"
                }]
            }
        ]
    },
    {
        key: "italy",
        name: "🇮🇹Italy",
        regions: [
            {
                key: "piedmont",
                name: "Piedmont",
                subregions: [
                {
                    key: "barolo",
                    name: "Barolo"
                },
                {
                    key: "barbaresco",
                    name: "Barbaresco"
                }]
            },
            {
                key: "tuscany",
                name: "Tuscany",
                subregions: [
                {
                    key: "brunello",
                    name: "Montalcino"
                },
                {
                    key: "chianti",
                    name: "Chianti"
                },
                {
                    key: "bolgheri",
                    name: "Bolgheri"
                }]
            },
            {
                key: "others",
                name: "Others",
                subregions: [
                {
                    key: "taurasi",
                    name: "Taurasi"
                },
                {
                    key: "alto_adige",
                    name: "Alto Adige"
                },
                {
                    key: "sicily",
                    name: "Sicily"
                },
                {
                    key: "veneto",
                    name: "Veneto"
                }]
            }
        ]
    },
    {
        key: "germany",
        name: "🇩🇪Germany",
        regions: [
            {
                key: "",
                name: "",
                subregions: [
                {
                    key: "mosel",
                    name: "Mosel"
                },
                {
                    key: "rheinhessen",
                    name: "Rhine"
                },
                {
                    key: "germany_pinot_noir",
                    name: "Pinot Noir"
                }]
            }
        ]
    },
    {
        key: "austria",
        name: "🇦🇹Austria",
        regions: [
            {
                key: "",
                name: "",
                subregions: [
                {
                    key: "austria",
                    name: "Austria"
                }]
            }
        ]
    },
    {
        key: "spain",
        name: "🇪🇸Spain",
        regions: [
            {
                key: "",
                name: "",
                subregions: [
                {
                    key: "rioja",
                    name: "Rioja"
                },
                {
                    key: "ribera_del_duero",
                    name: "Ribera Del Duero"
                },
                {
                    key: "priorat",
                    name: "Priorat"
                },
                {
                    key: "galicia",
                    name: "Galicia"
                }]
            }
        ]
    },
    {
        key: "usa",
        name: "🇺🇸United States",
        regions: [
            {
                key: "california",
                name: "California",
                subregions: [
                {
                    key: "california_napa",
                    name: "C/S"
                },
                {
                    key: "california_chadonnay",
                    name: "Chardonnay"
                },
                {
                    key: "california_pinot_noir",
                    name: "Pinot Noir"
                },
                {
                    key: "california_zinfandel",
                    name: "Zinfandel"
                }]
            },
            {
                key: "oregon",
                name: "Oregon",
                subregions: [
                {
                    key: "oregon_pinot_noir",
                    name: "Pinot Noir"
                }]
            },
            {
                key: "washington",
                name: "Washington",
                subregions: [
                {
                    key: "washington_reds",
                    name: "C/S"
                }]
            }
        ]
    },
    {
        key: "argentina",
        name: "🇦🇷Argentina",
        regions: [
            {
                key: "",
                name: "",
                subregions: [
                {
                    key: "mendoza",
                    name: "Mendoza"
                }]
            }
        ]
    },
    {
        key: "australia",
        name: "🇦🇺Australia",
        regions: [
            {
                key: "",
                name: "",
                subregions: [
                {
                    key: "mclaren_vale",
                    name: "Barossa/McLaren"
                },
                {
                    key: "victoria",
                    name: "Victoria/Tasmania"
                },
                {
                    key: "western_australia",
                    name: "Western Australia"
                },
            ]
            }
        ]
    },
    {
        key: "new_zealand",
        name: "🇳🇿New Zealand",
        regions: [
            {
                key: "",
                name: "",
                subregions: [
                {
                    key: "new_zealand",
                    name: "New Zealand"
                }]
            }
        ]
    },
    {
        key: "chile",
        name: "🇨🇱Chile",
        regions: [
            {
                key: "",
                name: "",
                subregions: [
                {
                    key: "chile",
                    name: "Chile"
                }]
            }
        ]
    },
    {
        key: "south_africa",
        name: "🇿🇦South Africa",
        regions: [
            {
                key: "",
                name: "",
                subregions: [
                {
                    key: "south_africa",
                    name: "South Africa"
                }]
            }
        ]
    },
];

export default countries;