import RatingHeader from './RatingHeader';
import RatingRegion from './RatingRegion';


function RatingCountry(props) {
    const { critic, country } = props;

    return (
        <>
        <RatingHeader title={country.name}/>
        {country.regions.map((region) => (
            <RatingRegion critic={critic} key={region.key} region={region} />
        ))}
        </>
    )
}

export default RatingCountry;