import { Component } from "react";
import { Text, Box } from '@chakra-ui/react'

import { AVERAGE, CRITICS, VINTAGES_INFO, RATING_BY_REGION } from "./constants/ratings";

class Rating extends Component {
    ScoreElement(props) {
        if (props.score) {
            const score = Math.round(props.score);
            const color = (
                score >= 96 ? "#e0c92f" :
                score >= 90 ? "#c9633a" :
                score >= 80 ? "#bd1919" :
                score >= 70 ? "#801220" :
                "#432652"
            );

            return ( <Box className="rating-table-cell rating-table-score" key={props.vintage} backgroundColor={ color }><Text fontSize="sm">{Math.round(props.score)}</Text></Box> );
        }
        else
            return ( <div className="rating-table-cell rating-table-not-rated" key={props.vintage}><Text fontSize="sm">NR</Text></div> );
    }

    render() {
        const { type, data, region, onClick } = this.props;
        const critic = type === "MAIN_VALUE" ? region : type;

        if (!data)
            return;

        return (
            <div className="rating-table-row">
                <div className="rating-table-cell rating-critic" onClick={onClick}>{critic}</div>
                {Array.from(Array(VINTAGES_INFO.length), (e, i) => {
                        const vintage = VINTAGES_INFO.max - i;

                        return (
                            <this.ScoreElement key={vintage} vintage={vintage} score={data[vintage]} />
                        )
                })}
            </div>
        )
    }
}

export default Rating;