

import RatingHeader from './RatingHeader';
import RatingSubRegion from './RatingSubRegion';


function RatingRegion(props) {
    const { critic, region } = props;

    return (
        <>
        <RatingHeader key={region.key} title={region.name} hideVintage={true} />
        {region.subregions.map((subregion) => (
            <RatingSubRegion key={subregion.key} critic={critic} region={subregion} />
        ))}
        </>
    )
}

export default RatingRegion;