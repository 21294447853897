import { useState } from "react";
import { useMediaQuery } from "@chakra-ui/react"

import Rating from './Rating';

import { AVERAGE, CRITICS, VINTAGES_INFO, RATING_BY_REGION, RATINGS } from "./constants/ratings";


function RatingSubRegion(props) {
    const [isShowing, setIsShowing] = useState(false);
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const { critic, region } = props;

    if (critic !== "average") {
        return (
            <Rating key={`${region.name}_main`} type="MAIN_VALUE" region={region.name} data={RATING_BY_REGION[region.key][critic]} />
        )
    }

    return (
        <>
            <Rating onClick={() => setIsShowing((isShowing) => !isShowing)} key={`${region.name}_main`} type="MAIN_VALUE" region={region.name} vintages_info={VINTAGES_INFO} data={AVERAGE[region.key]} />
            <div className={"table-row-collapse " + (isShowing ? "visible" : "hidden")}>
                {CRITICS.map(critic => (
                    <Rating key={`${region.name}_${critic}`} type={critic} data={RATINGS[critic][region.key]} />
                ))}
            </div>
        </>
    )
}

export default RatingSubRegion;