import { TabPanel } from '@chakra-ui/react'
import COUNTRIES from "./constants/countries";
import RatingCountry from "./RatingCountry";

function RatingTab(props) {
    const { critic } = props;

    return (
        
        <div className="div-table-wrapper">
            <div className="div-table">
                {COUNTRIES.map((country) => (
                    <RatingCountry key={country.key} country={country} critic={critic} />
                ))}
            </div>
        </div>
    )
}

export default RatingTab;